import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasClassicTheme } from 'common/src/theme/saasClassic';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasClassic/saasClassic.style';

import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';

// import BannerSection from '../containers/SaasClassic/Banner';
import Navbar from '../containers/SaasClassic/Navbar';
// import ServiceSection from '../containers/SaasClassic/Service';
// import PricingSection from '../containers/SaasClassic/Pricing';
// import PartnerSection from '../containers/SaasClassic/Partner';
// import TrialSection from '../containers/SaasClassic/Trial';
// import FeatureSection from '../containers/SaasClassic/Feature';
// import UpdateScreen from '../containers/SaasClassic/UpdateScreen';
// import TestimonialSection from '../containers/SaasClassic/Testimonial';
import Newsletter from '../containers/SaasClassic/Newsletter';
// import Footer from '../containers/SaasClassic/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

const SubLimit = ({
  row,
  col,
  contentWrapper,
  message,
  messageSmall,
  title,
}) => {
  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO title="podstacks | Subscription Limit!" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <Container className="message-body">
            <Box {...row}>
              <Box {...contentWrapper}>
                <Box {...row}>
                  <Heading {...title} content="Well, this is embarassing..." />
                </Box>

                <Box {...row}>
                  <Text
                    {...message}
                    content="Our servers are currently experiencing high volume traffic."
                  />

                  <Image
                    src="https://images.unsplash.com/photo-1549692520-acc6669e2f0c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1233&q=80"
                    alt="oh geez"
                    style={{
                      borderRadius: '10px',
                      height: '50%',
                      width: '50%',
                    }}
                  />
                </Box>

                <Box {...row}>
                  <Text
                    {...message}
                    content="Our team is already in the process of updating our service
                    to ensure you don't miss a beat.  So sit back and relax, we got this!"
                  />
                  <Text
                    {...message}
                    content="We really appreciate your patience! =)"
                  />
                </Box>
                <Box {...row}>
                  <a href="/#feature_section">
                    <Button
                      // {...fillButton}
                      title="Get me outta here!"
                    />
                  </a>
                </Box>
              </Box>
            </Box>
          </Container>

          {/* <BannerSection /> */}
          {/* <ServiceSection /> */}
          {/* <UpdateScreen /> */}
          {/* <FeatureSection /> */}
          {/* <PartnerSection /> */}
          {/* <PricingSection /> */}
          {/* <TestimonialSection /> */}
          {/* <TrialSection /> */}
          {/* <Newsletter /> */}
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

SubLimit.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '50px',
    mb: '50px',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pl: ['30px', '30px', '30px', '25px', '45px'],
    pr: ['30px', '30px', '30px', '25px', '45px'],
    mb: ['50px', '70px'],
  },
  contentWrapper: {
    width: ['100%', '100%', '60%', '60%', '60%'],
    mb: '40px',
  },
  title: {
    fontSize: ['36px', '44px', '44px', '44px', '58px'],
    // fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: 'secondaryWithBg',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  messageBig: {
    fontSize: ['18px', '26px', '34px', '36px', '40px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  message: {
    fontSize: '20px',
    // fontSize: ['12px', '20px', '28px', '30px', '34px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '-0.025em',
    // mt: '50px',
    mb: '50px',
    lineHeight: '1.2',
    textAlign: 'center',
  },
};

export default SubLimit;
